import React from 'react';
import Layout from '../components/Layout';
import { Helmet } from 'react-helmet';

export default function Contact() {
    return (
        <>
            <Helmet>
                <title>Kontaktné informácia - Feudarium - Webová hra</title>
            </Helmet>
            <Layout>
                <div className="row">
                    <div className="col-xs-12 col-sm-6">
                        <p>
                            Hru Feudarium prevádzkuje Milan Herda. Kontaktovať ma môžete na adrese
                            info&nbsp;zavináč&nbsp;feudarium.com
                        </p>

                        <table className="table">
                            <tr>
                                <th>Obchodné meno:</th>
                                <td>Bc. Milan Herda Perungrad</td>
                            </tr>
                            <tr>
                                <th>Právna forma</th>
                                <td>
                                    Fyzická osoba zapísaná v Živnostenskom registri Okresného úradu Nitra od 13. júla
                                    2009
                                </td>
                            </tr>
                            <tr>
                                <th>Číslo živnostenského registra</th>
                                <td>430-36279</td>
                            </tr>
                            <tr>
                                <th>IČO:</th>
                                <td>44 881 649</td>
                            </tr>
                            <tr>
                                <th>DIČ:</th>
                                <td>1 081 689 268</td>
                            </tr>
                            <tr>
                                <th>Adresa:</th>
                                <td>
                                    <address>
                                        Kpt. Jána Rašu 3147/21
                                        <br />
                                        Bratislava-Dúbravka
                                        <br />
                                        841 01
                                        <br />
                                        Slovenská republika
                                    </address>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </Layout>
        </>
    );
}
